<template>
  <div class="http404-container">
    <div class="http404">
      <div class="pic-404">
        <img
          class="pic-404__parent"
          src="@/assets/404/404.png"
          alt="404"
        />
        <img
          class="pic-404__child left"
          src="@/assets/404/404cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child mid"
          src="@/assets/404/404cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child right"
          src="@/assets/404/404cloud.png"
          alt="404"
        />
      </div>
      <div class="msg">
        <div class="msg__oops">
          OOPS!
        </div>
        <div class="msg__headline">
          {{ message }}
        </div>
        <div class="msg__info">
          Please check that the URL you entered is correct,
          or click the button below to return to the homepage.
        </div>
        <a
          href=""
          class="msg__return-home"
        >Back</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Page404',
    computed: {
      message() {
        return 'The webmaster said that you can not enter this page...';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/views/_404.scss';
</style>
